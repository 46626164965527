import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'url-redirection-app';
  siteLink = window.location.href;
  newUrl: string;
  constructor() {
    this.getUrl(this.siteLink);
  }

  getUrl(pUrl: string) {
    if (pUrl.includes('pharma2-admin')) {
      this.newUrl = 'https://pharma2-admin.web.bizmotionapp.com';
    } else if (pUrl.includes('sel')) {
      this.newUrl = 'https://sel.web.robisfa.com';
    } else if (pUrl.includes('albiondev') || pUrl.includes('albion')) {
      this.newUrl = 'https://albion.web.bizmotionapp.com';
    } else if (pUrl.includes('bizpharma')) {
      this.newUrl = 'https://bizpharma.web.bizmotionapp.com';
    } else if (pUrl.includes('goodman')) {
      this.newUrl = 'https://goodman.web.bizmotionapp.com';
    } else if (pUrl.includes('deeplaid')) {
      this.newUrl = 'https://deeplaid.web.bizmotionapp.com';
    } else if (pUrl.includes('maaoshishu')) {
      this.newUrl = 'https://maaoshishu.web.robisfa.com';
    } else if (pUrl.includes('sfaplus')) {
      this.newUrl = 'https://sfaplus.web.robisfa.com';
    } else if (pUrl.includes('sharif')) {
      this.newUrl = 'https://sharif.web.bizmotionapp.com';
    } else if (pUrl.includes('localhost')) {
      this.newUrl = 'https://sharif.web.bizmotionapp.com';
    } else if ((pUrl.includes('everest.'))) {
      this.newUrl = 'https://everest.web.bizmotionapp.com';
    } else if ((pUrl.includes('everestoncoviro.'))) {
      this.newUrl = 'https://everestoncoviro.web.bizmotionapp.com/';
    } else if (pUrl.includes('everest-admin.')) {
      this.newUrl = 'https://everest-admin.web.bizmotionapp.com/';
    } else if (pUrl.includes('beacon-admin.')) {
      this.newUrl = 'https://beacon-admin.web.bizmotionapp.com/';
    } else if (pUrl.includes('beacon2.')) {
      this.newUrl = 'https://beacon.web.bizmotionapp.com/';
    } else if (pUrl.includes('pharma1-admin.')) {
      this.newUrl = 'https://pharma1-admin.web.bizmotionapp.com/';
    } else if (pUrl.includes('somatec.')) {
      this.newUrl = 'https://somatec.web.bizmotionapp.com/';
    } else if (pUrl.includes('labaid.')) {
      this.newUrl = 'https://labaid.web.bizmotionapp.com/';
    } else if (pUrl.includes('whitehorse.')) {
      this.newUrl = 'https://whitehorse.web.bizmotionapp.com/';
    } else if (pUrl.includes('unicornhs.')) {
      this.newUrl = 'https://unicornhs.web.bizmotionapp.com/';
    } else if (pUrl.includes('fmcg1-admin.')) {
      this.newUrl = 'https://fmcg1-admin.web.bizmotionapp.com/';
    } else if (pUrl.includes('anh.')) {
      this.newUrl = 'https://anh.web.bizmotionapp.com/';
    } else if (pUrl.includes('mir.') || pUrl.includes('mirweb')) {
      this.newUrl = 'https://mir.web.bizmotionapp.com/';
    } else if (pUrl.includes('drinkwell.')) {
      this.newUrl = 'https://drinkwell.web.bizmotionapp.com/';
    } else if (pUrl.includes('akijplastics.')) {
      this.newUrl = 'https://akijplastics.web.bizmotionapp.com/';
    } else if (pUrl.includes('bizfmcg.')) {
      this.newUrl = 'https://bizfmcg.web.bizmotionapp.com/';
    } else if (pUrl.includes('fmcg2-admin.')) {
      this.newUrl = 'https://fmcg2-admin.web.bizmotionapp.com/';
    } else if (pUrl.includes('gazi.')) {
      this.newUrl = 'https://gazi.web.bizmotionapp.com/';
    } else if (pUrl.includes('navanawelding.')) {
      this.newUrl = 'https://navanawelding.web.bizmotionapp.com/';
    } else if (pUrl.includes('deshbandhu.')) {
      this.newUrl = 'https://deshbandhu.web.bizmotionapp.com/';
    } else if (pUrl.includes('orionweb.')) {
      this.newUrl = 'https://orion.web.bizmotionapp.com/';
    } else if (pUrl.includes('infusionweb.')) {
      this.newUrl = 'https://infusion.web.bizmotionapp.com/';
    } else if (pUrl.includes('orion-admin.')) {
      this.newUrl = 'https://orion-admin.web.bizmotionapp.com/';
    } else if (pUrl.includes('dblpharma.')) {
      this.newUrl = 'https://dblpharma.web.bizmotionapp.com/';
    } else if (pUrl.includes('dbl-admin.')) {
      this.newUrl = 'https://dblpharma-admin.web.bizmotionapp.com/';
    } else if (pUrl.includes('robisfa1.robimotion.com')) {
      this.newUrl = 'https://web.axentec.robisfa.com/';
    }
  }
}
